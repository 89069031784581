
export default {
    data() {
        return {
            darkMode:
                this.$cookie.get('darkMode') === 'true' ||
                this.$vuetify.theme.dark,
        }
    },
    watch: {
        darkMode(newValue) {
            this.$vuetify.theme.dark = newValue
            this.$cookie.set('darkMode', newValue)
        },
    },
    methods: {
        onSwitchMode() {
            this.darkMode = !this.darkMode
        },
    },
}
